import { Button } from '@material-tailwind/react';
import { lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';

const VideoPlayer = lazy(() => import('../Components/VideoPlayer.jsx'));

const Home = () => (
  <div className="p-6 sm:p-8">
    <div className="mb-8 rounded-xl overflow-hidden shadow-lg border-4 border-[#A173BF]">
      <Suspense fallback={<div>Loading video...</div>}>
        <VideoPlayer src="https://player.vimeo.com/video/1005533343?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" title="Hana Introduction" />
      </Suspense>
    </div>

    <p className="text-black mb-8 text-lg text-center">
      Discover insights about your mental health with Hana, an AI-powered tool developed by clinical psychologists.
    </p>

    <div className="grid gap-6 sm:grid-cols-3">
      <div className="bg-[#8DE8F2] p-6 rounded-xl shadow-lg transform transition-all duration-300 hover:scale-105">
        <h2 className="text-xl font-semibold text-black mb-3">1. Have a Conversation</h2>
        <p className="text-black">Engage in a natural conversation with Hana, just like you would in a therapy session.</p>
      </div>

      <div className="bg-[#A173BF] p-6 rounded-xl shadow-lg transform transition-all duration-300 hover:scale-105">
        <h2 className="text-xl font-semibold text-black mb-3">2. Receive AI Analysis</h2>
        <p className="text-black">Our advanced AI, grounded in psychological principles, analyzes your responses.</p>
      </div>

      <div className="bg-[#D98032] p-6 rounded-xl shadow-lg transform transition-all duration-300 hover:scale-105">
        <h2 className="text-xl font-semibold text-black mb-3">3. Get Your Report</h2>
        <p className="text-black">Receive a comprehensive mental health report via email with valuable insights.</p>
      </div>
    </div>

    <Link to="/start">
      <Button fullWidth size="md" className="mt-10">
        Start Your Journey Now
      </Button>
    </Link>
  </div>
);

export default Home;

import { useState } from 'react';
import PropTypes from 'prop-types';
import { useUser } from '@clerk/clerk-react';
import { Button, Input, Option, Select, Typography } from '@material-tailwind/react';
import { Controller, useForm } from 'react-hook-form';
import Loading from '../Components/Loading.jsx';
import { useAxios } from '../Components/AxiosProvider.jsx';

const Preferences = ({ onSave }) => {
  const { user, isLoaded } = useUser();
  const [isSaving, setIsSaving] = useState(false);
  const axios = useAxios();

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm({
    defaultValues: user?.unsafeMetadata?.preferences,
  });

  if (!isLoaded) return <Loading />;

  const onSubmit = async (data) => {
    setIsSaving(true);

    const updatedUser = await user.update({
      unsafeMetadata: {
        preferences: {
          ...user?.unsafeMetadata?.preferences,
          ...data,
        },
      },
    });

    reset(updatedUser?.unsafeMetadata?.preferences);

    await axios.post('/calls/generate');

    onSave();

    setIsSaving(false);
  };

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <h3 className="text-lg font-semibold mb-4">Preferences</h3>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-3">
        <Typography color="gray" variant="small">
          Here you can customize the preferences for your weekly appointments.
        </Typography>

        <Controller
          control={control}
          name="dayOfWeek"
          render={({ field }) => (
            <Select label="Day of Week" value={field.value} onChange={field.onChange}>
              <Option value="1">Monday</Option>
              <Option value="2">Tuesday</Option>
              <Option value="3">Wednesday</Option>
              <Option value="4">Thursday</Option>
              <Option value="5">Friday</Option>
              <Option value="6">Saturday</Option>
              <Option value="7">Sunday</Option>
            </Select>
          )}
        />

        <Controller control={control} name="time" render={({ field }) => <Input label="Time" type="time" value={field.value} onChange={field.onChange} />} />

        <Controller
          control={control}
          name="timezone"
          render={({ field }) => (
            <Select label="Timezone" value={field.value} onChange={field.onChange}>
              {Intl.supportedValuesOf('timeZone').map((timezone) => (
                <Option key={timezone} value={timezone}>
                  {timezone}
                </Option>
              ))}
            </Select>
          )}
        />

        <Button size="sm" fullWidth color="deep-purple" type="submit" disabled={!isDirty} loading={isSaving}>
          Save
        </Button>
      </form>
    </div>
  );
};

Preferences.propTypes = {
  onSave: PropTypes.func.isRequired,
};

export default Preferences;

import { createContext, useContext, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useAuth } from '@clerk/clerk-react';

const AxiosContext = createContext(null);

export const useAxios = () => {
  const axiosInstance = useContext(AxiosContext);
  if (!axiosInstance) {
    throw new Error('useAxios must be used within an AxiosProvider');
  }
  return axiosInstance;
};

export const AxiosProvider = ({ children, jwtToken }) => {
  const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_NODE_API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const [token, setToken] = useState(jwtToken);
  const { getToken } = useAuth();

  setInterval(() => {
    getToken().then((t) => setToken(t));
  }, 1000);

  axiosInstance.interceptors.request.use(
    (config) => {
      if (jwtToken) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  return <AxiosContext.Provider value={axiosInstance}>{children}</AxiosContext.Provider>;
};

AxiosProvider.propTypes = {
  children: PropTypes.node.isRequired,
  jwtToken: PropTypes.string,
};

import { RedirectToSignIn, useAuth } from '@clerk/clerk-react';
import { Outlet } from 'react-router-dom';
import Loading from './Components/Loading.jsx';

const AuthedLayout = () => {
  const { userId, isLoaded } = useAuth();

  if (!isLoaded) return <Loading />;
  if (!userId) return <RedirectToSignIn />;

  return <Outlet />;
};

export default AuthedLayout;

import { Typography } from '@material-tailwind/react';
import { FaClock } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { formatDistanceToNow } from 'date-fns';
import Loading from '../Components/Loading.jsx';

const Appointment = ({ appointment }) => {
  return (
    <div className="flex space-x-4 p-4 rounded-lg hover:bg-gray-50 transition-colors cursor-pointer items-center">
      <div className="flex-shrink-0">
        <FaClock className="text-purple-700" />
      </div>

      <div className="flex-1">
        <div className="flex items-center justify-between">
          <h4 className="text-sm font-medium text-gray-900">Catch-up call with HANA AI</h4>
          <span className="text-xs text-gray-500">{formatDistanceToNow(new Date(appointment.date), { addSuffix: true })}</span>
        </div>
        <p className="text-sm text-gray-500 mt-1">
          {new Date(appointment.date).toLocaleString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          })}
        </p>
      </div>
    </div>
  );
};

Appointment.propTypes = {
  appointment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
  }).isRequired,
};

const Appointments = ({ appointments }) => {
  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <h3 className="text-lg font-semibold mb-4">Your next appointments</h3>

      {appointments ? (
        <>
          {appointments.length === 0 ? (
            <Typography color="gray" variant="small">
              You have no upcoming appointments.
            </Typography>
          ) : (
            <div className="space-y-4">
              {appointments.map((appointment) => (
                <Appointment key={appointment.id} appointment={appointment} />
              ))}
            </div>
          )}
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

Appointments.propTypes = {
  appointments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    }),
  ),
};

export default Appointments;

import { useUser } from '@clerk/clerk-react';
import { DailyAudio, DailyAudioTrack, DailyProvider, useAppMessage, useCallObject, useDaily, useParticipantIds } from '@daily-co/daily-react';
import { Spinner } from '@material-tailwind/react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../Components/Loading.jsx';

const CallComponent = ({ name, email, phone, uid }) => {
  const navigate = useNavigate();
  const daily = useDaily();
  const participantIds = useParticipantIds({ filter: 'remote' });

  const [roomData, setRoomData] = useState(null);
  const [hasBotJoined, setHasBotJoined] = useState(false);
  const [bot, setBot] = useState(null);

  useAppMessage({
    onAppMessage: (e) => {
      if (!daily || typeof e.data?.is_final === 'undefined') return;

      // Determine the UI state from the cue sent by the bot
      if (e.data.is_final) {
        // Delay enabling local mic input to avoid feedback from LLM
        setTimeout(() => daily.setLocalAudio(true), 500);
      } else {
        daily.setLocalAudio(true);
      }
    },
  });

  useEffect(() => {
    if (window.secondLoad || !daily) return;

    window.secondLoad = true;

    const fetchData = async () => {
      const response = await fetch(`${import.meta.env.VITE_APP_URL ?? ''}/create`, {
        headers: { Accept: 'application/json' },
      });

      const room = await response.json();

      await daily.join({
        url: room.room_url,
        token: room.token,
        videoSource: false,
        startAudioOff: false,
      });

      daily.setLocalAudio(true);

      const startResponse = await fetch(`${import.meta.env.VITE_APP_URL ?? ''}/start`, {
        method: 'POST',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify({
          room_url: `${room.room_url}?t=${room.token}`,
          name,
          email,
          phone_number: phone,
          uid,
        }),
      });

      setBot(await startResponse.json());
      setRoomData(room);
    };

    fetchData();
  }, [daily, name, email, phone, uid]);

  useEffect(() => {
    if (participantIds.length > 0) {
      setHasBotJoined(true);
    }
  }, [participantIds]);

  if (hasBotJoined && participantIds.length === 0) {
    navigate(`/report/${bot.bot_id}`);
    return <Spinner />;
  }

  return (
    <div className="absolute translate-x-0 translate-y-0">
      <DailyAudio />

      {participantIds.length > 0 ? <DailyAudioTrack sessionId={participantIds[0]} type="audio" /> : null}
      {roomData && participantIds.length > 0 ? null : <Spinner />}
    </div>
  );
};

CallComponent.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
};

const Call = () => {
  const { user, isLoaded } = useUser();

  const callObject = useCallObject({
    audioSource: true,
    videoSource: false,
  });

  if (!isLoaded) return <Loading />;

  return (
    <DailyProvider callObject={callObject}>
      <div className="fixed top-0 left-0 w-full h-[100vh] bg-[#E8E7E7] flex justify-center items-center">
        <div
          data-poster-url="https://cdn.prod.website-files.com/663a0be5516fae11bc3eaf4a%2F667604a27634133738dfd216_Sequence%2001_12-poster-00001.jpg"
          data-video-urls="/bg-call.mp4,/bg-call.webm"
          data-autoplay="true"
          data-loop="true"
          data-wf-ignore="true"
          id="w-node-_037cbddd-d641-7aa0-ec02-409414d88767-bb6bec62"
          className="w-background-video w-background-video-atom w-full h-full object-cover"
        >
          <video
            id="037cbddd-d641-7aa0-ec02-409414d88767-video"
            autoPlay
            loop
            style={{
              backgroundImage:
                "url('https://cdn.prod.website-files.com/663a0be5516fae11bc3eaf4a%2F667604a27634133738dfd216_Sequence%2001_12-poster-00001.jpg')",
            }}
            muted
            playsInline
            data-wf-ignore="true"
            data-object-fit="cover"
            className="w-full h-full object-cover"
          >
            <source src="/bg-call.mp4" data-wf-ignore="true" />
            <source src="/bg-call.webm" data-wf-ignore="true" />
          </video>
        </div>

        <CallComponent name={user.fullName} email={user.emailAddresses[0].emailAddress} phone={user.unsafeMetadata.phone_number} uid={user.id} />
      </div>
    </DailyProvider>
  );
};

export default Call;

import { useEffect, useState } from 'react';
import { Activity, AlertCircle, CheckCircle, ChevronDown, ChevronUp, Info, Target, User } from 'react-feather';
import { useParams } from 'react-router-dom';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell, Legend } from 'recharts';
import PropTypes from 'prop-types';
import Loading from '../Components/Loading.jsx';

const Section = ({ onToggle, isExpanded, title, icon, children }) => {
  return (
    <div className="mb-6 border border-gray-200 rounded-lg overflow-hidden">
      <div className="bg-gray-100 p-4 flex justify-between items-center cursor-pointer" onClick={onToggle}>
        <h2 className="text-xl font-semibold flex items-center">
          {icon}
          <span className="ml-2">{title}</span>
        </h2>
        {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </div>
      {isExpanded && <div className="p-4">{children}</div>}
    </div>
  );
};

Section.propTypes = {
  onToggle: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
};

const Report = () => {
  const { reportId } = useParams();
  const [report, setReport] = useState(null);
  const [expandedSections, setExpandedSections] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${import.meta.env.VITE_APP_URL ?? ''}/report/${reportId}`, {
        headers: { Accept: 'application/json' },
      });

      setReport(await response.json());
    };

    fetchData();
  }, [reportId]);

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  if (!report) return <Loading />;

  const colors = {
    primary: '#3B82F6',
    secondary: '#6B7280',
    accent: '#10B981',
    background: '#F3F4F6',
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h1 className="text-3xl font-bold mb-6 text-blue-600">Your Personalized Mental Health Dashboard</h1>

      <div className="flex justify-between items-center mb-8">
        <div>
          <p className="text-lg font-medium text-gray-700">{report.userData.name}</p>
          <p className="text-sm text-gray-500">{report.userData.email}</p>
        </div>
      </div>

      <Section
        title="Important Information and Disclaimers"
        icon={<Info className="text-red-500" />}
        onToggle={() => toggleSection('disclaimers')}
        isExpanded={expandedSections.disclaimers}
      >
        <div className="space-y-4 text-sm text-gray-600">
          <p>
            <strong>Purpose of this Dashboard:</strong> This dashboard provides a summary of your responses to our mental health assessment. It is designed to
            give you insights into your current mental health status and offer general recommendations.
          </p>
          <p>
            <strong>Not a Diagnostic Tool:</strong> This dashboard and the associated assessment are not diagnostic tools. They do not replace professional
            medical advice, diagnosis, or treatment.
          </p>
          <p>
            <strong>Seek Professional Help:</strong> If you are experiencing severe symptoms or have concerns about your mental health, please consult with a
            qualified mental health professional or your healthcare provider.
          </p>
          <p>
            <strong>Confidentiality:</strong> Your privacy is important to us. This information is confidential and protected under applicable privacy laws.
          </p>
          <p>
            <strong>Accuracy of Information:</strong> The insights and recommendations provided are based solely on the information you provided in your
            assessment. The accuracy and completeness of this dashboard depend on the accuracy and completeness of your responses.
          </p>
          <p>
            <strong>Emergency Services:</strong> If you are in crisis or thinking about harming yourself, please seek immediate help. Contact your local
            emergency services or a crisis helpline immediately.
          </p>
        </div>
      </Section>

      <Section
        title="Summary and Key Insights"
        icon={<AlertCircle className="text-yellow-500" />}
        onToggle={() => toggleSection('summary')}
        isExpanded={expandedSections.summary}
      >
        <ul className="list-disc pl-6">
          {report.sections.summary_insights.map((insight, index) => (
            <li key={index} className="mb-2">
              {insight}
            </li>
          ))}
        </ul>
      </Section>

      <Section
        title="Mental Health Profile"
        icon={<Activity className="text-green-500" />}
        onToggle={() => toggleSection('profile')}
        isExpanded={expandedSections.profile}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={report.sections.mental_health_profile}>
                <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                <XAxis dataKey="name" tick={{ fill: colors.secondary }} />
                <YAxis tick={{ fill: colors.secondary }} />
                <Tooltip />
                <Bar dataKey="value" fill={colors.primary} />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={report.sections.mental_health_profile}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                >
                  {report.sections.mental_health_profile.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Section>

      <Section
        title="Pre-diagnostic Assessment"
        icon={<Target className="text-blue-500" />}
        onToggle={() => toggleSection('preDiagnostic')}
        isExpanded={expandedSections.preDiagnostic}
      >
        <div className="mb-4 p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
          <p className="font-bold">Important Note:</p>
          <p>
            This pre-diagnostic assessment is based on the information you&quot;ve provided. It is not a formal diagnosis and should be discussed with a mental
            health professional for accurate evaluation and treatment.
          </p>
        </div>
        <div className="space-y-4">
          {report.sections.pre_diagnostic_assessment.conditions.map((condition, index) => (
            <div key={index} className="border-l-4 pl-4 py-2" style={{ borderColor: COLORS[index % COLORS.length] }}>
              <h3 className="font-medium text-gray-700">{condition.condition}</h3>
              <p className="text-sm text-gray-500">Likelihood: {condition.likelihood}</p>
              <p className="text-sm text-gray-600 mt-1">{condition.description}</p>
            </div>
          ))}
        </div>
      </Section>

      <Section
        title="Your Action Plan"
        icon={<CheckCircle className="text-blue-500" />}
        onToggle={() => toggleSection('actionPlan')}
        isExpanded={expandedSections.actionPlan}
      >
        <ol className="list-decimal pl-6">
          {report.sections.action_plan.map((action, index) => (
            <li key={index} className="mb-2">
              {action}
            </li>
          ))}
        </ol>
      </Section>

      <Section
        title="Recommended Therapy Pathway"
        icon={<User className="text-purple-500" />}
        onToggle={() => toggleSection('therapyPathway')}
        isExpanded={expandedSections.therapyPathway}
      >
        <ol className="list-decimal pl-6">
          {report.sections.recommended_therapy_pathway.map((step, index) => (
            <li key={index} className="mb-2">
              {step}
            </li>
          ))}
        </ol>
      </Section>

      <div className="mt-8 text-sm text-gray-500 border-t pt-4">
        <p>
          <strong>Last Updated:</strong> {new Date().toLocaleDateString()}
        </p>
        <p>
          <strong>Assessment Version:</strong> 1.0
        </p>
        <p>© {new Date().getFullYear()} HANA. All rights reserved.</p>
        <p>For questions or concerns about this report, please contact our support team at support@usehana.com.</p>
      </div>
    </div>
  );
};

export default Report;

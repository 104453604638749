import { RedirectToSignIn, useSession } from '@clerk/clerk-react';
import { Navigate } from 'react-router-dom';
import Loading from '../Components/Loading.jsx';

const Login = () => {
  const { isLoaded, isSignedIn } = useSession();

  if (!isLoaded) return <Loading />;
  if (isSignedIn) return <Navigate to="/" />;
  return <RedirectToSignIn />;
};

export default Login;

import { Typography } from '@material-tailwind/react';

const SignIn = () => (
  <div className="p-8 max-w-2xl mx-auto">
    <Typography variant="h3" className="text-center font-bold">
      Let&apos;s get you set up
    </Typography>

    <Typography variant="small" className="text-center">
      We&apos;ve sent you an email with a link to sign in.
    </Typography>
  </div>
);

export default SignIn;

import { useCallback, useEffect, useState } from 'react';
import { useUser } from '@clerk/clerk-react';
import Preferences from './Preferences.jsx';
import Appointments from './Appointments.jsx';
import { useAxios } from '../Components/AxiosProvider.jsx';

const Index = () => {
  const currentDate = new Date().toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const { user } = useUser();

  const axios = useAxios();
  const [appointments, setAppointments] = useState(null);

  const fetchAppointments = useCallback(() => {
    axios.get('/calls/upcoming').then((res) => setAppointments(res.data));
  }, [axios]);

  useEffect(() => {
    fetchAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex justify-between items-center">
          <div>
            <h2 className="text-2xl font-bold text-gray-900">Welcome, {user.fullName}</h2>
            <p className="text-gray-500">{currentDate}</p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2">
          <Appointments appointments={appointments} />
        </div>

        <Preferences onSave={fetchAppointments} />
      </div>
    </div>
  );
};

export default Index;

import { useEffect, useState } from 'react';
import { ClerkProvider, RedirectToSignIn, useAuth, UserButton, useUser } from '@clerk/clerk-react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import Loading from '../Components/Loading.jsx';
import { AxiosProvider } from '../Components/AxiosProvider.jsx';

const AuthedContainer = () => {
  const { getToken, userId, isLoaded } = useAuth();
  const [token, setToken] = useState(null);
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getToken().then((t) => setToken(t));
  }, [getToken]);

  if (!isLoaded) return <Loading />;
  if (!userId) return <RedirectToSignIn />;
  if (!token) return <Loading />;

  return (
    <div className="min-h-screen bg-gray-50">
      <aside className="fixed top-0 left-0 h-full w-64 bg-white shadow-sm z-30">
        <div className="flex items-center h-16 px-6 border-b border-gray-100">
          <div className="flex items-center space-x-3">
            <div className="w-8 h-8 rounded-full bg-gradient-to-r from-teal-400 to-purple-400 flex items-center justify-center">
              <span className="text-white font-bold text-lg">H</span>
            </div>
            <span className="text-xl font-bold bg-gradient-to-r from-teal-500 to-purple-500 bg-clip-text text-transparent">HANA AI</span>
          </div>
        </div>
        <nav className="p-4">
          <button
            onClick={() => navigate('/auth/dashboard')}
            className={`w-full text-left px-4 py-3 rounded-lg transition-all duration-200 flex items-center mb-1 group ${
              location.pathname === '/auth/dashboard'
                ? 'bg-gradient-to-r from-teal-500/10 to-purple-500/10 text-teal-600'
                : 'text-gray-600 hover:bg-gradient-to-r hover:from-teal-500/5 hover:to-purple-500/5'
            }`}
          >
            <span
              className={`mr-3 transition-transform duration-200 group-hover:scale-110 ${
                location.pathname === '/auth/dashboard' ? 'text-teal-500' : 'text-gray-400'
              }`}
            >
              <FaHome />
            </span>
            <span className="text-sm font-medium">Settings</span>
          </button>
        </nav>
      </aside>

      <header className="fixed top-0 left-64 right-0 h-16 bg-white shadow-sm flex items-center justify-between px-6 z-20">
        <div className="flex items-center">&nbsp;</div>
        <div className="flex items-center space-x-6">
          <div className="flex items-center space-x-3 pl-6 border-l border-gray-200">
            <div className="flex flex-col items-end">
              <p className="font-medium text-gray-700">{user.fullName}</p>
            </div>
            <UserButton />
          </div>
        </div>
      </header>

      <main className="ml-64 pt-16">
        <div className="p-6 max-w-7xl mx-auto">
          <AxiosProvider jwtToken={token}>
            <Outlet />
          </AxiosProvider>
        </div>
      </main>
    </div>
  );
};

const Container = () => {
  const navigate = useNavigate();

  return (
    <ClerkProvider
      routerPush={(to) => navigate(to)}
      routerReplace={(to) => navigate(to, { replace: true })}
      publishableKey={import.meta.env.VITE_CLERK_PUBLISHABLE_KEY}
      afterSignOutUrl="/"
    >
      <AuthedContainer />
    </ClerkProvider>
  );
};

export default Container;
